import React, {useEffect, useState} from "react";
import {useHistory} from "react-router";
import {Button} from "@material-ui/core";
import {ON_AMAZON} from "../../../router/routesMap";
import {toAbsoluteUrl} from "../../../../_metronic";
import useThank from "../../../hooks/thank.hook";
import useUsers from "../../../hooks/users.hook";
import {useParseHistory} from "../../../hooks/modal.hook";
import './subscription.scss'
import {OnboardingLayout} from "../Onboarding/components";
import toast from "../../components/toast/toast";

const ThankYouPage = () => {
  const { user, getUsersInfo } = useUsers({ fetch: false });
  const { addThankData } = useThank();
  const history = useParseHistory();
  const historyRouter = useHistory();

  const waitForAffaim = (callback) => {
    const checkAffaim = () => {
      if (window.affaim) {
        callback();
      } else {
        setTimeout(checkAffaim, 100); // Check again after 100ms
      }
    };
    checkAffaim();
  };

  function separateCurrencyAmount(value) {
    const regex = /([^\d]+)?([\d,.]+)([^\d]+)?/;
    const match = value.match(regex);

    if (match) {
        let amount = "";
        let currency = "";

        if (match[1]) {
            currency += match[1];
        }
        if (match[2]) {
            amount += match[2];
        }
        if (match[3]) {
            currency += match[3];
        }

        return {
            amount: amount.trim(),
            currency: !currency.trim().includes("£") ?  "USD" : "GBP"
        };
    }

    return {
        amount: `${value || "unknown"}`,
        currency: "unknown"
    };
}

  useEffect(() => {
    const { invoice_amount, transaction_id } = history;
    const { amount, currency } = separateCurrencyAmount(invoice_amount)

    getUsersInfo();
    addThankData({
      values: {
        ...history,
        user: user.id,
      },
      onDone: () => {
        waitForAffaim(() => {
          console.log("send affaim")
          console.log({
            externalOrderId: `${transaction_id}`,
            email: user?.email,
            fullname: `${user?.first_name} ${user?.last_name}`,
            currency: currency,
            items: [
              {
                price: +amount,
                affaimOfferId: process.env.REACT_APP_API_AFFAIM_OFFER_ID,
                quantity: 1
              }
            ]
          })
          window.affaim.sendPurchase({
            externalOrderId: `${transaction_id}`,
            email: user?.email,
            fullname: `${user?.first_name} ${user?.last_name}`,
            currency: currency,
            items: [
              {
                price: +amount,
                affaimOfferId: 'OFF2fICLHD4EOTJ',
                quantity: 1
              }
            ]
          });
          console.log("end send affaim")
        });
        getUsersInfo();
        toast.success("Success save!", { duration: 4000 }, { isClose: false });
        console.log("end toast Success")
      },
      onError: (error) => {
        // toast.error(error, {duration: null}, {isClose: false});
        console.log('error');
      }
    });
    // send purchase event to Google Analytics
    window.gtag('event', 'conversion', {
      'send_to': 'AW-16679846460/XpqWCITrkNIZELyEyZE-',
      'transaction_id': `${transaction_id}`
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const {subscription_name, next_billing_date} = history;

  return (
    <OnboardingLayout currentStep={2} title="Subscribe to a plan.">
      <div className="first-page subscription thank">
      <div className="first-page__header">
        <div className="first-page__logo">
          <img src={toAbsoluteUrl("/media/logos/new-logo.svg")} alt=""/>
        </div>
      </div>

      <div className="first-page__body">
        <h3 className="first-page__title">Thank You</h3>
        <h3 className="first-page__description">Your subscription to {subscription_name} is successful.</h3>
        <h3 className="first-page__description">
          Your trial period ends on {next_billing_date} after which your card will be charged.
          However, you will not be charged if you cancel before the trial period.
        </h3>

        <Button
          size="large"
          className="connect-doddled__logout next"
          onClick={() => {historyRouter.push(ON_AMAZON)}}
        >
          Next
        </Button>
      </div>
    </div>
    </OnboardingLayout>
  )
};

export default ThankYouPage;
